/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/assets/css/fonts.css';
import './src/assets/css/modern-normalize.css';
import './src/assets/css/misc.css';
import './src/assets/css/style.css';
import './src/assets/css/media.css';
import './src/bootstrap';
import './src/assets/css/modal-video.scss';
import { scrollTo } from "./src/components/utility/utils";
export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
    document.body.classList.add("kuda-hide")
    const hide = () => {
        document.body.classList.remove("kuda-hide")
    }
    scrollTo(0, hide);
};

export const onClientEntry = () => {
    window.onload = () => {
        window.scrollTo(0, 0)
    }
}