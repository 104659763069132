import React from "react"
import axios from "axios"
import { Component } from "react"

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "Access-Control-Allow-Origin": "*",
  crossorigin: "true",
}

axios.defaults.credentials = "same-origin"
// axios.defaults.baseURL = `${process.env.GATSBY_APP_API_URL}`;
axios.defaults.headers.post["Content-Type"] = "application/json"
// axios.defaults.headers.common['Token'] = `${process.env.GATSBY_APP_KEY}`;
class MyApp extends Component {
  render() {
    const { Component, pageProps } = this.props
    return <Component {...pageProps} />
  }
}

export default MyApp
