export const isScrolledIntoView = (el) => {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;

}

export const scrollToElement = () => {
    Array.from(document.querySelectorAll(".kuda-preview > div")).forEach(
        element => {
            if (isScrolledIntoView(element)) {
                element.classList.add('fade-active');
            }
            // else {
            //     element.classList.remove('fade-active')
            // }
        }
    );
}

export const pageLoad = () => {
    setTimeout(() => {
        document.querySelector(".kuda-entry").classList.add("kuda-page--transition");
    }, 0);
}

export const debounce = (func, wait, immediate) => {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const scrollTo = (offset, callback) => {
    const fixedOffset = offset.toFixed(),
        onScroll = function () {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll)
                callback()
            }
        }

    window.addEventListener('scroll', onScroll)
    onScroll()
    window.scrollTo({
        top: offset,
        behavior: 'smooth'
    })
}

export const checkEmpty = (obj) => {
    let check = false;
    for (var key in obj) {
        if (obj[key] === null || obj[key] === "") {
            check = true;
            break;
        }
    }
    return check;
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}